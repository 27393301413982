import { ScreenDimensions } from 'model/enums/screen-dimensions-types';
import styled from 'styled-components/macro';

export const ScreenContent = styled.div`
  max-width: 1440px;
  width: 100%;
  padding: 40px 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledTitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  color: var(--gray-dark-1, #1b1a1b);
  font-family: Satoshi;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 36.4px;
`;

export const StyledText = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 8px 0px 0px;
  color: var(--gray-dark-4, #656666);
  font-family: Satoshi;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
`;

export const StepBox = styled.div`
  width: 100%;
  margin: 48px 0px 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px;
`;
