import { ScreenDimensions } from 'model/enums/screen-dimensions-types';
import styled from 'styled-components/macro';

export const StyledInfoList = styled.div`
  width: 1440px;
  min-height: 136px;
  height: 100%;

  padding: 0px 128px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${({ theme }) => theme.color.lpColors.infinityDataScreen};

  @media (max-width: ${ScreenDimensions.TABLET}) {
    padding: 0px 64px;
  }
  @media (max-width: ${ScreenDimensions.MID_TABLET}) {
    flex-wrap: wrap;
    row-gap: 24px;
    padding: 24px 16px;
    justify-content: center;
    column-gap: 12px;
  }
`;

export const Info = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  svg {
    width: 32px;
    height: 32px;
    path {
      fill: ${({ theme }) => theme.color.lpColors?.infinityDataIcon};
    }
    circle {
      fill: ${({ theme }) => theme.color.lpColors?.infinityDataCircle};
    }
  }
  @media (max-width: ${ScreenDimensions.TABLET}) {
    text-align: center;
  }

  @media (max-width: ${ScreenDimensions.MID_TABLET}) {
    max-width: 136px;
    width: 100%;
  }
`;

export const StyledText = styled.div`
  margin: 8px 0px 0px;
  padding: 0px 4px;
`;
