import styled from 'styled-components/macro';

interface Props {
  customStyle?: any;
}

export const StyledOption = styled.div<Props>`
  max-width: 632px;
  padding: ${({ customStyle }) => (customStyle.isActive ? '0px 0px 24px 0px' : '0px')};
  border-bottom: ${({ theme }) => `1px solid ${theme.color.lightGray2}`};
  transition: all 1s ease;

  margin: 16px 0px 16px;
  padding-bottom: 16px;

  cursor: pointer;
`;

export const OptionTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  color: #525252;

  svg {
    width: 16px;
    height: 16px;
    path {
      fill: ${({ theme }) => theme.color.primaryColor};
    }
  }
`;

export const OptionDescription = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: #8d8d8d;
  margin: 16px 0px 0px 0px;

  @media (max-width: 671px) {
    font-size: 12px;
  }
`;

export const StyledImg = styled.div`
  min-width: 24px;
  min-height: 24px;
  margin: 0px 0px 0px 4px;
  cursor: pointer;
  svg {
    min-width: 24px;
    min-height: 24px;
  }
`;

export const StyledQuestionTitle = styled.div`
  color: #656666;
  font-family: Satoshi;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
`;
