import { Markup } from 'interweave';
import { StyleType } from 'model/text-processing';
import { DefaultText } from './styled';

interface TextProcessingProps {
  text?: string;
  customStyle: StyleType;
}

const TextProcessing: React.FC<TextProcessingProps> = ({ text, customStyle }) => {
  const renderProcessedtext = () => {
    text = text
      ?.replace(/<b[^>]*>/g, `<span id='bold'>`)
      .replace(/<\/b>/g, '</span>')
      .replace(/<color[^>]*>/g, `<span id='color'>`)
      .replace(/<\/color>/g, '</span>');

    return (
      <DefaultText customStyle={customStyle}>
        <Markup content={text} />
      </DefaultText>
    );
  };

  return renderProcessedtext();
};

export default TextProcessing;
