import styled from 'styled-components/macro';

interface Props {
  isReverse?: boolean;
  backgroundImage?: string;
}

export const StepCard = styled.div<Props>`
  max-width: 280px;
  width: 100%;

  background: #fff;
  border-radius: 16px;

  display: flex;
  flex-direction: ${({ isReverse }) => (isReverse ? 'column-reverse' : 'column')};
  align-items: center;
  padding: 16px;
`;

export const ImageBox = styled.div<Props>`
  width: 100%;
  position: relative;
  display: flex;
  align-items: ${({ isReverse }) => (isReverse ? 'flex-end' : 'flex-start')};
`;

export const TextBox = styled(ImageBox)`
  padding-top: 16px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const StyledImage = styled.div<Props>`
  width: 100%;
  height: 100%;
  z-index: 0;

  border-radius: ${({ isReverse }) => (isReverse ? '0px 0px 16px 16px' : '16px 16px 0px 0px')};

  position: absolute;

  background-image: url(${({ backgroundImage }) => backgroundImage});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

export const StepCount = styled.div<Props>`
  width: 56px;
  height: 56px;

  border-radius: 16px;
  border-radius: ${({ isReverse }) => (isReverse ? '0px 16px 0px 16px' : '16px 0px 16px 0px')};

  background: ${({ theme }) => theme.color.lpColors.whatIsScreen};

  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: 700;
  font-size: 20px;
  z-index: 1;

  color: ${({ theme }) => theme.color.lpColors.whatIsScreenTextColor};
`;

export const StepTitle = styled.div`
  align-self: flex-start;
  margin: 0px;
  color: var(--gray-bw-black, #000);
  font-family: Satoshi;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
`;

export const StepText = styled.div`
  align-self: flex-start;
  margin: 8px 0px 0px;
  color: var(--gray-dark-4, #656666);
  font-family: Satoshi;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
`;

export const PositionStyled = styled.div`
  display: flex;
  width: 32px;
  height: 32px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: #fff;
  text-align: center;
  font-family: Satoshi;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  border-radius: 50%;
  margin-right: auto;
  ${({ theme }) => {
    return `
          background-color: ${theme.color.lpColors.stepColor}; `;
  }}
`;
