import Footer from 'components/general-components/footer';
import Header from 'components/general-components/header';
import LandingPageContainer from 'components/landing-page';
import { NO_LANDING_PAGE, ORGANIZATION_NAME } from 'config/constants';
import { HeaderVariants } from 'model/enums/header-variants';
import { OrganizationName } from 'model/enums/organization-name';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

interface LandingPageProps {}

const LandingPage: React.FC<LandingPageProps> = props => {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (NO_LANDING_PAGE && location.pathname !== '/D41D8CD98F00B204E9800998ECF8427E') history.push('/admin/login');
  });

  return (
    <>
      <Header variant={HeaderVariants.PRIMARY} />
      <LandingPageContainer />
      <Footer />
    </>
  );
};

export default LandingPage;
