import TextProcessing from 'components/text-processing';
import { styledText, styledTitle } from 'components/text-processing/style-constants';
import { StepByStepImage } from 'images/step-by-step';
import { TextVariants } from 'model/enums/text-variants';
import { stepByStepType } from 'model/landing-page';
import { useTheme } from 'styled-components';
import { StyledScreenContainer } from 'themes/globalStyles';
import Step from '../step';
import { ScreenContent, StepBox, StyledText, StyledTitle } from '../style';
import { StyledGoToSimulatorButton } from 'components/landing-page/what-is-screen/style';
import { useTranslation } from 'react-i18next';

interface MobileProps {
  data?: stepByStepType;
}

const Mobile: React.FC<MobileProps> = ({ data }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const scrollToTop = () => {
    window.scrollTo({
      top: 375,
      behavior: 'smooth',
    });
  };

  const mockexportstepByStepType = {
    title: 'Como funciona o credito pessoal Azure?',
    text: 'Veja como é fácil pedir um empréstimo pessoal rápido, seguro e sem burocracia na Azure.',
    steps: [
      {
        title: '',
        text: '',
      },
    ],
    sectionId: 'string',
  };

  return (
    <StyledScreenContainer
      customStyle={{
        height: 'auto',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#F6F6F6',
      }}
      id={data?.sectionId}
    >
      <ScreenContent>
        <StyledTitle>{mockexportstepByStepType?.title}</StyledTitle>
        <StyledText>{mockexportstepByStepType?.text}</StyledText>

        <StepBox>
          {data?.steps.map((item, idx) => (
            <Step key={idx} item={item} cardImage={StepByStepImage[idx]} position={`0${idx + 1}`} isReverse={idx % 2 !== 0} />
          ))}
        </StepBox>
        <StyledGoToSimulatorButton onClick={scrollToTop}>{t('simulatorData.title')}</StyledGoToSimulatorButton>
      </ScreenContent>
    </StyledScreenContainer>
  );
};

export default Mobile;
