import React from 'react';
import { useTheme } from 'styled-components';

interface Props {
  isWhite?: boolean;
  isSecundaryLogo?: boolean;
}

export const Logo: React.FC<Props> = ({ isWhite, isSecundaryLogo }) => {
  const theme = useTheme();

  return isSecundaryLogo ? (
    <svg width="39" height="38" viewBox="0 0 39 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Logo" clip-path="url(#clip0_626_4156)">
        <g id="Group 631823">
          <g id="Group 631822">
            <g id="Group 44">
              <g id="Group 3">
                <mask id="mask0_626_4156" maskUnits="userSpaceOnUse" x="27" y="10" width="11" height="18">
                  <path
                    id="Clip 2"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M27.5648 19.0133L35.8556 27.3176C37.1376 24.7741 37.8592 21.9017 37.8592 18.8615C37.8592 15.9821 37.2122 13.2537 36.0547 10.8123L27.5648 19.0133Z"
                    fill="white"
                  />
                </mask>
                <g mask="url(#mask0_626_4156)">
                  <path
                    id="Fill 1"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M35.9772 28.5023L26.4768 19.0345L35.9546 9.58936L45.4545 19.0571L35.9772 28.5023Z"
                    fill="#88B929"
                  />
                </g>
              </g>
              <g id="Group 6">
                <mask id="mask1_626_4156" maskUnits="userSpaceOnUse" x="10" y="27" width="18" height="11">
                  <path
                    id="Clip 5"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M10.6774 35.8445C13.1715 37.0504 15.9715 37.7266 18.9296 37.7266C21.8961 37.7266 24.7028 37.047 27.2021 35.8348L18.9454 27.6077L10.6774 35.8445Z"
                    fill="white"
                  />
                </mask>
                <g mask="url(#mask1_626_4156)">
                  <path
                    id="Fill 4"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M18.9327 45.5112L9.42175 36.0326L18.9447 26.5422L28.4554 36.0208L18.9327 45.5112Z"
                    fill="#51ACCF"
                  />
                </g>
              </g>
              <g id="Group 9">
                <mask id="mask2_626_4156" maskUnits="userSpaceOnUse" x="1" y="18" width="18" height="18">
                  <path
                    id="Clip 8"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M1.98474 27.2805C3.85146 31.0042 6.91862 34.0278 10.6798 35.8459L18.9454 27.6078L10.3057 18.9973L1.98474 27.2805Z"
                    fill="white"
                  />
                </mask>
                <g mask="url(#mask2_626_4156)">
                  <path
                    id="Fill 7"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M20.0124 27.6081L10.301 17.9297L0.0587158 28.137L9.77032 37.8155L20.0124 27.6081Z"
                    fill="#4F93CB"
                  />
                </g>
              </g>
              <g id="Group 12">
                <mask id="mask3_626_4156" maskUnits="userSpaceOnUse" x="10" y="0" width="18" height="11">
                  <path
                    id="Clip 11"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M18.5623 0C15.7731 0.0528263 13.1315 0.707226 10.7629 1.83771C10.6003 1.93344 10.5189 1.99123 10.5189 1.99123L18.9455 10.3867L27.3946 2.00417C27.3946 2.00417 27.3486 1.97182 27.2569 1.91512C24.8469 0.736765 22.1497 0.05412 19.2973 0H18.5623Z"
                    fill="white"
                  />
                </mask>
                <g mask="url(#mask3_626_4156)">
                  <path
                    id="Fill 10"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M18.9633 11.4696L9.45215 1.99103L18.9501 -7.47437L28.4613 2.00419L18.9633 11.4696Z"
                    fill="#4158A1"
                  />
                </g>
              </g>
              <g id="Group 15">
                <mask id="mask4_626_4156" maskUnits="userSpaceOnUse" x="1" y="1" width="18" height="18">
                  <path
                    id="Clip 14"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M1.89844 10.6175L10.3057 18.9972L18.9455 10.3868L10.4963 1.96753C6.76074 3.82335 3.72633 6.87456 1.89844 10.6175Z"
                    fill="white"
                  />
                </mask>
                <g mask="url(#mask4_626_4156)">
                  <path
                    id="Fill 13"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M10.3058 20.0601L-0.0135498 9.77601L9.69284 0.102539L20.0121 10.3869L10.3058 20.0601Z"
                    fill="#4A69AB"
                  />
                </g>
              </g>
              <g id="Group 18">
                <mask id="mask5_626_4156" maskUnits="userSpaceOnUse" x="0" y="10" width="11" height="18">
                  <path
                    id="Clip 17"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0 18.8617C0 21.8898 0.715932 24.7513 1.98817 27.2874L10.3056 18.9973L1.89968 10.6147C0.682965 13.1058 0 15.9047 0 18.8617Z"
                    fill="white"
                  />
                </mask>
                <g mask="url(#mask5_626_4156)">
                  <path
                    id="Fill 16"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M1.85194 28.4864L-7.65601 19.011L1.86756 9.51978L11.3755 18.9951L1.85194 28.4864Z"
                    fill="#517DBA"
                  />
                </g>
              </g>
              <g id="Group 21">
                <mask id="mask6_626_4156" maskUnits="userSpaceOnUse" x="18" y="2" width="19" height="18">
                  <path
                    id="Clip 20"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M18.929 10.4021L27.5672 19.0139L36.0543 10.8116C34.2938 7.09845 31.3533 4.04983 27.7145 2.14657C27.5071 2.05299 27.3878 2.00513 27.3878 2.00513L18.929 10.4021Z"
                    fill="white"
                  />
                </mask>
                <g mask="url(#mask6_626_4156)">
                  <path
                    id="Fill 19"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M18.1746 19.7653H36.8519V1.25342H18.1746V19.7653Z"
                    fill="#39549E"
                  />
                </g>
              </g>
              <g id="Group 24">
                <mask id="mask7_626_4156" maskUnits="userSpaceOnUse" x="18" y="19" width="18" height="17">
                  <path
                    id="Clip 23"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M18.9438 27.607L27.1991 35.8361C27.4877 35.6962 27.7721 35.5493 28.0523 35.3954L34.9461 28.9214C35.2999 28.3632 35.6248 27.7856 35.9193 27.1898L27.5613 19.0112L18.9438 27.607Z"
                    fill="white"
                  />
                </mask>
                <g mask="url(#mask7_626_4156)">
                  <path
                    id="Fill 22"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M27.6438 37.3422L17.8723 27.6043L27.6407 17.8691L37.412 27.6073L27.6438 37.3422Z"
                    fill="#5FB6A1"
                  />
                </g>
              </g>
              <path
                id="Fill 25"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M27.5572 19.0012C27.5572 19.0012 30.1874 21.3256 33.0034 23.455C35.8196 25.5842 38.2548 26.9178 38.2548 26.9178C38.3268 26.9579 38.345 27.0438 38.2938 27.1102C38.2938 27.1102 36.2699 29.8448 33.0034 32.2238C29.7371 34.6027 27.323 35.7737 27.323 35.7737L27.5572 19.0012Z"
                fill="#D9D024"
              />
              <g id="Group 29">
                <mask id="mask8_626_4156" maskUnits="userSpaceOnUse" x="27" y="19" width="12" height="17">
                  <path
                    id="Clip 28"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M27.323 35.7737C27.323 35.7737 29.7371 34.6024 33.0036 32.2235C36.2699 29.8446 38.294 27.1099 38.294 27.1099C38.345 27.0437 38.327 26.9579 38.2548 26.9176C38.2548 26.9176 35.8196 25.5842 33.0036 23.4548C30.1876 21.3253 27.5572 19.001 27.5572 19.001L27.323 35.7737Z"
                    fill="white"
                  />
                </mask>
                <g mask="url(#mask8_626_4156)">
                  <path
                    id="Fill 27"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M-0.588867 38.0815H38.4675V-0.841797H-0.588867V38.0815Z"
                    fill="#EDE21E"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_626_4156">
          <rect width="38.95" height="38" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ) : (
    <svg width="92" height="32" viewBox="0 0 92 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_203_209" maskUnits="userSpaceOnUse" x="20" y="8" width="9" height="13">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.6114 14.6517L26.7962 20.9323C27.7525 19.0087 28.2908 16.8362 28.2908 14.5369V14.5369C28.2908 12.3591 27.8082 10.2956 26.9447 8.4491V8.4491L20.6114 14.6517Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_203_209)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.8869 21.8284L19.7998 14.6678L26.87 7.52429L33.9568 14.6849L26.8869 21.8284Z"
          fill="#88B929"
        />
      </g>
      <mask id="mask1_203_209" maskUnits="userSpaceOnUse" x="8" y="21" width="13" height="8">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.01369 27.3813C9.87429 28.2934 11.963 28.8048 14.1697 28.8048V28.8048C16.3827 28.8048 18.4764 28.2908 20.3409 27.374V27.374L14.1815 21.1517L8.01369 27.3813Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_203_209)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.172 34.6925L7.07701 27.5237L14.1809 20.3459L21.2758 27.5147L14.172 34.6925Z"
          fill="#51ACCF"
        />
      </g>
      <mask id="mask2_203_209" maskUnits="userSpaceOnUse" x="1" y="14" width="14" height="14">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.52908 20.9042C2.92162 23.7205 5.20967 26.0073 8.01546 27.3823V27.3823L14.1815 21.1517L7.73637 14.6395L1.52908 20.9042Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask2_203_209)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.9773 21.1519L7.73282 13.832L0.0922241 21.5519L7.33691 28.8719L14.9773 21.1519Z"
          fill="#4F93CB"
        />
      </g>
      <mask id="mask3_203_209" maskUnits="userSpaceOnUse" x="7" y="0" width="14" height="9">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.8956 0.271545C11.815 0.311499 9.84435 0.806432 8.07743 1.66143V1.66143C7.95609 1.73384 7.89542 1.77754 7.89542 1.77754V1.77754L14.1815 8.1272L20.4844 1.78733C20.4844 1.78733 20.4501 1.76287 20.3817 1.71998V1.71998C18.5838 0.828773 16.5718 0.312477 14.4439 0.271545V0.271545H13.8956Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask3_203_209)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.1948 8.94621L7.09959 1.77743L14.1849 -5.38141L21.2801 1.78737L14.1948 8.94621Z"
          fill="#4158A1"
        />
      </g>
      <mask id="mask4_203_209" maskUnits="userSpaceOnUse" x="1" y="1" width="14" height="14">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.46469 8.30167L7.73637 14.6394L14.1815 8.12718L7.87858 1.75958C5.09189 3.16317 2.82827 5.47085 1.46469 8.30167V8.30167Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask4_203_209)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.7364 15.4433L0.0383759 7.66526L7.27918 0.34906L14.9772 8.12725L7.7364 15.4433Z"
          fill="#4A69AB"
        />
      </g>
      <mask id="mask5_203_209" maskUnits="userSpaceOnUse" x="0" y="8" width="8" height="13">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.0485382 14.5369C0.0485382 16.8271 0.582611 18.9913 1.53168 20.9093V20.9093L7.73637 14.6394L1.46567 8.29956C0.558019 10.1836 0.0485382 12.3004 0.0485382 14.5369V14.5369Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask5_203_209)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.43003 21.8161L-5.66273 14.6497L1.44168 7.47131L8.53445 14.6377L1.43003 21.8161Z"
          fill="#517DBA"
        />
      </g>
      <mask id="mask6_203_209" maskUnits="userSpaceOnUse" x="14" y="1" width="13" height="14">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.1692 8.13894L20.6132 14.6522L26.9444 8.44862C25.6311 5.64031 23.4376 3.33459 20.723 1.89512V1.89512C20.5684 1.82435 20.4794 1.78815 20.4794 1.78815V1.78815L14.1692 8.13894Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask6_203_209)">
        <path fillRule="evenodd" clipRule="evenodd" d="M13.6065 15.2205H27.5395V1.21967H13.6065V15.2205Z" fill="#39549E" />
      </g>
      <mask id="mask7_203_209" maskUnits="userSpaceOnUse" x="14" y="14" width="13" height="14">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.1804 21.1512L20.3386 27.3749C20.554 27.2691 20.7661 27.1581 20.9751 27.0416V27.0416L26.1178 22.1453C26.3817 21.7231 26.624 21.2862 26.8438 20.8356V20.8356L20.6088 14.65L14.1804 21.1512Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask7_203_209)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.6704 28.514L13.381 21.149L20.6681 13.7862L27.9573 21.1513L20.6704 28.514Z"
          fill="#5FB6A1"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.6058 14.6425C20.6058 14.6425 22.5678 16.4004 24.6685 18.0109C26.7694 19.6213 28.586 20.6299 28.586 20.6299C28.6397 20.6603 28.6533 20.7252 28.6151 20.7754C28.6151 20.7754 27.1052 22.8437 24.6685 24.6429C22.2319 26.4421 20.431 27.3277 20.431 27.3277L20.6058 14.6425Z"
        fill="#D9D024"
      />
      <mask id="mask8_203_209" maskUnits="userSpaceOnUse" x="20" y="14" width="9" height="14">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.431 27.3278C20.431 27.3278 22.2319 26.442 24.6686 24.6428V24.6428C27.1052 22.8436 28.6152 20.7753 28.6152 20.7753V20.7753C28.6532 20.7253 28.6398 20.6604 28.5859 20.6299V20.6299C28.5859 20.6299 26.7693 19.6214 24.6686 18.0109V18.0109C22.5679 16.4003 20.6057 14.6424 20.6057 14.6424V14.6424L20.431 27.3278Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask8_203_209)">
        <path fillRule="evenodd" clipRule="evenodd" d="M-0.390717 29.0732H28.7447V-0.365112H-0.390717V29.0732Z" fill="#EDE21E" />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M63.7435 13.9759H66.8834C68.6159 13.9759 69.6919 12.842 69.6919 11.017C69.6919 9.78305 69.2127 8.95903 68.2674 8.56814C67.7745 8.37327 67.1088 8.27379 66.2914 8.27379H63.7435V13.9759ZM82.9522 8.12996C79.6241 8.12996 77.0173 10.8915 77.0173 14.417C77.0173 18.063 79.6241 20.9193 82.9522 20.9193C86.2666 20.9193 88.8631 18.063 88.8631 14.417C88.8631 10.8915 86.2666 8.12996 82.9522 8.12996V8.12996ZM56.9957 23.2141H49.9887V20.8953H52.0729V8.15393H49.9887V5.8593H56.9957V8.15393H54.8879V20.8953H56.9957V23.2141ZM73.8095 23.2141H72.1652C70.6591 23.2141 70.2558 22.9026 69.6503 21.8314L67.068 17.2095C66.686 16.566 66.4485 16.3904 65.5098 16.3904H63.7435V20.8953H65.8041V23.2141H58.8445V20.8953H60.9287V8.15393H58.8445V5.8593H66.3152C67.5804 5.8593 68.5485 5.92127 69.4646 6.22997C71.4132 6.89548 72.5306 8.57042 72.5306 10.8254C72.5306 13.1998 71.326 14.9832 69.2863 15.6714C69.4373 15.8147 69.6143 16.0217 69.7579 16.2999L72.1219 20.4579C72.3209 20.8053 72.5197 20.8953 73.0889 20.8953H73.8095V23.2141ZM40.9062 23.5014C35.7127 23.5014 31.9433 19.7111 31.9433 14.4887C31.9433 11.9893 32.8289 9.72271 34.4368 8.10664C36.0631 6.47214 38.369 5.57196 40.9298 5.57196C43.6877 5.57196 47.5717 6.67451 47.5717 9.12553V11.4342H44.9461V9.96357C44.9461 8.74149 42.9135 8.08218 41.001 8.08218C37.2662 8.08218 34.8531 10.5687 34.8531 14.417C34.8531 18.3349 37.3523 20.9672 41.072 20.9672C42.7163 20.9672 45.325 20.4565 45.325 19.0858V17.0846H42.0565V14.766H47.9506V19.7323C47.9506 22.4781 43.6298 23.5014 40.9062 23.5014V23.5014ZM82.9522 23.5014C80.5459 23.5014 78.3157 22.5675 76.6731 20.8717C75.034 19.1798 74.1313 16.8874 74.1313 14.417C74.1313 12.0048 75.0349 9.77017 76.6753 8.12441C78.316 6.47833 80.5454 5.57196 82.9522 5.57196C87.8983 5.57196 91.7727 9.45723 91.7727 14.417C91.7727 19.511 87.8983 23.5014 82.9522 23.5014V23.5014Z"
        fill={isWhite ? '#FEFEFE' : '#000000'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M66.8132 30.9843L67.3052 31.4803L66.8264 31.9628L66.3344 31.467L66.8132 30.9843"
        fill={isWhite ? '#FEFEFE' : '#000000'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M69.1262 27.4176H67.467V26.9074H71.35V27.4176H69.6911V31.7997H69.1262V27.4176"
        fill={isWhite ? '#FEFEFE' : '#000000'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M72.4825 26.9074H76.0068V27.4105H73.0327V29.081H75.6934V29.5842H73.0327V31.2966H76.0419V31.7997H72.4825V26.9074"
        fill={isWhite ? '#FEFEFE' : '#000000'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M77.0126 29.2859V29.272C77.0126 27.8894 78.037 26.7443 79.4633 26.7443C80.3425 26.7443 80.8686 27.0585 81.3532 27.5194L80.9795 27.9243C80.5709 27.5335 80.1141 27.254 79.4563 27.254C78.3834 27.254 77.5802 28.1337 77.5802 29.2582V29.272C77.5802 30.4032 78.39 31.2899 79.4563 31.2899C80.1207 31.2899 80.5571 31.0316 81.021 30.5846L81.381 30.9406C80.8756 31.4575 80.3217 31.7997 79.4427 31.7997C78.0441 31.7997 77.0126 30.6894 77.0126 29.2859"
        fill={isWhite ? '#FEFEFE' : '#000000'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M82.5135 26.9074H83.0593V29.081H85.8506V26.9074H86.3965V31.7997H85.8506V29.598H83.0593V31.7997H82.5135V26.9074"
        fill={isWhite ? '#FEFEFE' : '#000000'}
      />
    </svg>
  );
};
