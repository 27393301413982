import TextProcessing from 'components/text-processing';
import { styledTitle } from 'components/text-processing/style-constants';
import { TextVariants } from 'model/enums/text-variants';
import { WhatIsScreenType } from 'model/landing-page';
import { useTheme } from 'styled-components';
import { StyledScreenContainer } from 'themes/globalStyles';
import {
  InfoContent,
  NewStyledText,
  NewStyledTitle,
  ScreenContent,
  ShadowElipse1,
  ShadowElipse2,
  StyledGoToSimulatorButton,
  StyledImage,
  StyledList,
  StyledNewItem,
} from '../style';
import { WhatsIsImage } from 'images/what-is-screen';
import { useTranslation } from 'react-i18next';
import { ReactElement } from 'react';

interface MobileProps {
  data?: WhatIsScreenType;
}

export const newIconList: ReactElement[] = [
  <WhatsIsImage.circlePercentage />,
  <WhatsIsImage.circleMobilePhone />,
  <WhatsIsImage.circleShield />,
];

const Mobile: React.FC<MobileProps> = ({ data }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const scrollToTop = () => {
    window.scrollTo({
      top: 375,
      behavior: 'smooth',
    });
  };

  return (
    <StyledScreenContainer
      customStyle={{ height: 'auto', alignItems: 'center', justifyContent: 'center', background: theme.color.lpColors.whatIsScreen }}
      id={data?.sectionId}
    >
      <ScreenContent>
        <InfoContent>
          <TextProcessing text={data?.title} customStyle={styledTitle(TextVariants.SECONDARY, theme, true)} />
          <StyledImage src={WhatsIsImage?.background2mobile} draggable="false" />
          <StyledList>
            {data?.steps &&
              data?.steps.map((item, idx) => (
                <StyledNewItem key={`mobileNewItem-${idx}`}>
                  {newIconList[idx]}
                  <NewStyledTitle>{item.title}</NewStyledTitle>
                  <NewStyledText>{item.text}</NewStyledText>
                </StyledNewItem>
              ))}
          </StyledList>
          <StyledGoToSimulatorButton onClick={scrollToTop}>{t('simulatorData.title')}</StyledGoToSimulatorButton>
        </InfoContent>
      </ScreenContent>
      <ShadowElipse1 />
      <ShadowElipse2 />
    </StyledScreenContainer>
  );
};

export default Mobile;
