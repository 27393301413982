import { ScreenDimensions } from 'model/enums/screen-dimensions-types';
import styled from 'styled-components/macro';
import shadowElipse3 from '../../../images/shadowElipse3.png';

export const ScreenContent = styled.div`
  max-width: 1440px;
  width: 100%;
  padding: 40px 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledTitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  color: var(--gray-dark-1, #1b1a1b);
  font-family: Satoshi;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 36.4px;
`;

export const StyledText = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 8px 0px 0px;
  color: var(--gray-dark-4, #656666);
  font-family: Satoshi;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
`;

export const StepBox = styled.div`
  width: 100%;

  margin: 48px 0px 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  row-gap: 24px;
`;

export const InfoContainer = styled.div`
  max-width: 1440px;
  width: 100%;
  min-width: 480px;

  padding: 72px 128px;
  padding-top: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1200px) {
    padding: 72px 96px;
    padding-top: 12px;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    img {
      display: none;
    }
  }
`;

export const StyledImage = styled.img`
  max-width: 576px;
  width: auto;
  height: auto;
  margin-left: 24px;
  object-fit: contain;
  border-radius: 6%;

  @media (max-width: ${ScreenDimensions.MID_WEB}) {
    margin: 0px 0px 0px 0px;
  }
  @media only screen and (max-width: 675px) {
    width: 100%;
    margin-top: 24px;
  }
`;

export const TablesContainer = styled.div`
  gap: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 54px 24px;
  margin-left: 70px;
  @media (max-width: 1200px) {
    align-items: center;
    margin: 20px auto;
  }
`;

export const CardBlack = styled.div`
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 2px 32px 0px rgba(0, 0, 0, 0.08);
  display: flex;
  width: 450px;
  flex-direction: column;
  align-items: flex-start;
  div > .innerCard:first-child {
    border-radius: 0% 0% 0% 16px;
  }
  div > .innerCard:last-child {
    border-radius: 0% 0% 16px 0%;
  }

  @media (max-width: 500px) {
    width: 288px;
  }
`;

export const ShadowElipse2 = styled.div`
  z-index: 0;

  background-repeat: no-repeat;
  position: absolute;
  width: 274px;
  height: 56px;

  background-image: url(${shadowElipse3});
`;

export const CardRed = styled.div`
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 2px 32px 0px rgba(0, 0, 0, 0.08);
  display: flex;
  width: 450px;
  flex-direction: column;
  align-items: flex-start;
  div > .innerCard:nth-child(4) {
    border-radius: 0% 0% 0% 16px;
  }
  div > .innerCard:last-child {
    border-radius: 0% 0% 16px 0%;
  }
  @media (max-width: 500px) {
    width: 288px;
    div > .innerCard:nth-child(4) {
      border-radius: 0px;
    }
    div > .innerCard:nth-child(5) {
      border-radius: 0% 0% 0% 16px;
    }
    div > .innerCard:last-child {
      border-radius: 0% 0% 16px 0%;
    }
  }
`;

export const BlackTitle = styled.div`
  border-radius: 16px 16px 0px 0px;
  display: flex;
  height: 57px;
  padding: 8px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  background: #333;
  color: var(--gray-bw-white, #fff);
  text-align: center;
  font-family: Satoshi;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
`;

export const RedTitle = styled.div`
  border-radius: 16px 16px 0px 0px;
  display: flex;
  height: 57px;
  padding: 8px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  background: #e2162e;
  color: var(--gray-bw-white, #fff);
  text-align: center;
  span {
    font-family: Satoshi;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    z-index: 1;
  }
  p {
    color: var(--gray-bw-white, #fff);
    font-family: Satoshi;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    z-index: 1;
    margin-bottom: 0;
  }
`;

export const InnerCard = styled.div`
  display: flex;
  min-height: 78px;
  padding: 16px 0px;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  flex: 1;
  min-width: 148px;
  background: white;
  color: var(--gray-dark-4, #656666);
  font-family: Satoshi;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  @media (max-width: 500px) {
    min-width: 142px;
  }
`;

export const InnerTable = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  background: #e5e7e7;
  gap: 2px;
  border-radius: 16px;
`;

export const BigNumber = styled.div`
  color: var(--gray-bw-black, #000);
  font-family: Satoshi;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  span {
    color: var(--gray-dark-4, #656666);
    font-family: Satoshi;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    margin-right: 2px;
  }
`;

export const SpecialSpan = styled.div`
  text-align: center;
  a {
    color: var(--gray-dark-4, #656666);
    text-align: center;
    font-family: Satoshi;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    text-decoration: none;
  }
`;
