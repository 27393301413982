import styled from 'styled-components/macro';

interface Props {
  customStyle?: any;
}

export const FooterContainer = styled.div<Props>`
  height: 396px;
  background: ${({ theme }) => theme.color.lpColors.footerLp};
  padding: 0px 132px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 1055px) {
    padding: 24px 32px;
    height: auto;
    align-items: center;
  }
`;

export const FooterContent = styled.div`
  max-width: 1440px;
  width: 100%;
  height: 100%;
  padding: 0px 132px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  @media (max-width: 1055px) {
    width: 672px;
    padding: 0px 32px;
  }
  @media (max-width: 1055px) {
    width: 272px;
    padding: 0px 32px;
  }
`;

export const LogoBox = styled.div`
  max-width: 140px;
  display: flex;
  cursor: pointer;
`;

export const LinksBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  column-gap: 64px;

  margin: 36px 0px 0px;

  @media (max-width: 1055px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
`;

export const SectionLinks = styled.div`
  cursor: pointer;

  @media (max-width: 1055px) {
    display: flex;
    margin: 8px 0px;
    flex-direction: column;
    align-items: center;
  }
`;

export const StyledTitle = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
  margin: 0px 0px 8px 0px;

  @media (max-width: 1055px) {
    display: flex;
    justify-content: center;
  }
`;

export const LinksItemBox = styled.div<Props>`
  display: flex;
  flex-direction: ${({ customStyle }) => (customStyle.isActive ? 'row' : 'column')};

  @media (max-width: 1055px) {
    flex-direction: ${({ customStyle }) => (customStyle.isActive ? 'row' : 'column')};
    align-items: center;
  }
`;

export const StyledLink = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  cursor: pointer;
  margin: 8px 0px 0px;

  display: flex;

  :hover {
    text-decoration: underline;
  }
`;

export const SocialMediaBox = styled.div``;

export const SocialIcon = styled.div``;

export const StyledImage = styled.img`
  width: 32px;
  height: 32px;
`;

export const CopyrightBox = styled.div`
  width: 100%;
  height: 40px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  border-top: 1px solid ${({ theme }) => theme.color.lightGray2};
  background: ${({ theme }) => theme.color.lpColors.footerLp};
  color: ${({ theme }) => theme.color.white};
`;
