import TextProcessing from 'components/text-processing';
import { styledTitle } from 'components/text-processing/style-constants';
import { TextVariants } from 'model/enums/text-variants';
import { FaqScreenType } from 'model/landing-page';
import { useTheme } from 'styled-components';
import { StyledScreenContainer } from 'themes/globalStyles';
import Option from '../option';
import { FaqContainer, OptionList, StyledTitle } from '../styled';

interface DesktopProps {
  data?: FaqScreenType;
  currentPosition: number;
  setCurrentPosition: (position: number) => void;
}

const Desktop: React.FC<DesktopProps> = ({ currentPosition, setCurrentPosition, data }) => {
  const theme = useTheme();

  return (
    <StyledScreenContainer
      customStyle={{
        width: '100%',
        height: 'auto',
        background: theme.color.lpColors.faqScreen,
        alignItems: 'center',
        justifyContent: 'center',
      }}
      id={data?.sectionId}
    >
      <FaqContainer>
        <StyledTitle>{data?.title}</StyledTitle>

        <OptionList>
          {data?.questions.map((data, index) => (
            <Option
              key={index}
              data={data}
              position={index + 1}
              currentPosition={currentPosition}
              setCurrentPosition={setCurrentPosition}
            />
          ))}
        </OptionList>
      </FaqContainer>
    </StyledScreenContainer>
  );
};

export default Desktop;
